<template>
	<div>
		<Error v-if="error"></Error>
		<div v-else>
			<component :is="initComponent" />
		</div>
		<!-- <ModalFormationEndIn10Days
			:enterprise="profileInfos.entreprise_id"
			:formationData="formationInfos"
		></ModalFormationEndIn10Days> -->
	</div>
</template>

<script>
import Error from '../../views/layouts/Error';
import ModalFormationEndIn10Days from '../../components/other/ModalFormationEndIn10Days';
import { mapGetters } from 'vuex';
import { substractFromNow } from '../../utils/helper';

export default {
	name: 'ExerciceComponent',
	components: {
		Error,
		ModalFormationEndIn10Days,
	},
	computed: {
		...mapGetters('exercice', {
			exerciceType: 'exerciceType',
		}),
		...mapGetters('profile', {
			profileInfos: 'profileInfos',
			formationInfos: 'formationInfos',
			formationProgress: 'formationProgress',
		}),
	},
	data() {
		return {
			error: false,
			initComponent: '',
		};
	},
	methods: {
		substractFromNow,
	},
	async created() {
		try {
			const exerciseID = this.$route.params.idExercice;

			if (this.profileInfos.entreprise_id == null && localStorage.getItem('token'))
				await this.$store.dispatch('profile/getProfileAndExerciseFormationInfos');

			await this.$store.dispatch('exercice/getExercice', {
				idFormation: this.$route.params.idFormation,
				idExercice: this.$route.params.idExercice,
			});
			await this.$store.dispatch('exercice/resetScoreExercice');
			// if (this.profileInfos.entreprise_id  && this.profileInfos.entreprise_id._id == null && localStorage.getItem('token') != null) {
			// 	await this.$store.dispatch('profile/getProfileAndExerciseFormationInfos');
			// }

			if (exerciseID != null && exerciseID != 'undefined' && exerciseID != 'null' && exerciseID != '') {
				await this.$store.dispatch('exercice/getNoFeedbacksExercices', {
					exerciceId: this.$route.params.idExercice,
					after: this.substractFromNow(5),
					rating: 5,
					userId: this.profileInfos._id,
				});
			}

			if (this.formationProgress == null)
				this.$store.dispatch('profile/formationProgress', { idFormation: this.$route.params.idFormation });
			this.initComponent = async () => await import(`./type/${this.exerciceType}.vue`);
		} catch (err) {
			this.error = true;
		}
	},
};
</script>
